const planeData = [
  {
    name: "樂士文號",
    img: "/1.jpg",
    content: `「樂士文號」教練機原機為民國12年楊仙逸擔任我國航空局局長時，自行設計完成的第一架自製機，當年8月6日試飛成功，8月9日由國父孫中山命名。`
  },
  {
    name: "霍克Ⅲ型戰鬥機",
    img: "/2.jpg",
    content: `民國25年，首架霍克Ⅲ型機於同年3月19日運抵上海，後續並成立「中正隊」（第21中隊前身），李桂丹擔任首任隊長，其後併入第四大隊。民國26年8月14日，我空軍健兒由第四大隊高志航大隊長率隊，駕駛霍克Ⅲ型戰機迎擊來犯之日本鹿屋航空隊，並以劣勢裝備及超高戰技，創下4:0輝煌戰績。`
  },
  {
    name: "AT-6教練機",
    img: "/AT-6.jpg",
    content:
      "民國27年首由空軍官校採用此型飛機，民國32年新疆伊寧事件，曾以此型飛機編組成軍，擔任對地攻擊任務，民國35年擔任官校中、高級教練機，部隊亦編有該機，供作戰訓練熟飛及聯絡之用，至民國49年除役。"
  },
  {
    name: "P-40戰鷹式戰鬥機",
    img: "/3.jpg",
    content: `民國31年自美國接收，為我國抗戰後期主力機種，給予日軍致命打擊，使日軍失去在中國的制空權，於民國34年除役。`
  },
  {
    name: "PT-17初級教練機",
    img: "/PT-17.jpg",
    content:
      "抗戰期間，美提供我國150架，民國31年空軍官校初級班在印度北部臘河設立分校即使用PT-17實施初級飛行訓練。36年遷返杭州筧橋，38年遷台後，空軍第3 飛機製造廠曾仿造PT-17初教一型教練機104架，空軍官校16-42期初級班在虎尾基地即以此型機實施初級飛行訓練，迄至民國47年除役。"
  },
  {
    name: "C-47（空中列車式）運輸機",
    img: "/C-47.jpg",
    content:
      "民國33年即加入本軍服役，擔任抗戰、復員、戡亂時期之運兵、空投、空運等任務，民國38年遷台後擔任空投及空運等任務，並兼負官校空運組飛行訓練之用。該型機服役時間長達50年，為我空中運輸寫出重要史頁，貢獻深鉅，至民國83年7月除役。"
  },
  {
    name: "C-46運輸機",
    img: "/C-46.jpg",
    content:
      "民國34年美軍移交180架C-46機給予我國，隨即加入本軍服役，擔任抗戰、復員及戡亂時期之運兵、空投、空運等任務，遷台後擔任大陸空投、特種作戰、遠洋、本島、離島之行政運輸及官校空運組飛行訓練之任務。民國42年空軍成立特種作戰部隊，出動C-46機空投物資及傳單。民國44年3月一江山之役，出動C-46機14架實施密支作戰。民國47年台海戰役期間，C-46機在敵綿密砲火下圓滿達成對金門空運或空投補給品任務，表現卓越，至民國58年除役。"
  },
  {
    name: "B-26侵入者式空投機",
    img: "/B-26.jpg",
    content:
      "我空軍第34（黑蝙蝠）中隊於民國43年3月開始使用拆除武器之B-26型機執行台海偵巡、敵後電子反制、偵察及空投等任務，直至民國49年除役。"
  },
  {
    name: "P-47霹靂式戰鬥機",
    img: "/4.jpg",
    content: `民國35年美軍移交我軍，替換老舊P-40戰機，用於對日作戰，主要擔任護航和要地防空任務，並曾擊落性能較優之米格15機，於民國49年除役。`
  },
  {
    name: "P-51野馬式戰鬥機",
    img: "/5.jpg",
    content: `民國33年抗日戰爭末期自美軍接收，主要擔任對地密接支援及戰術轟炸，為日軍零式機之剋星，戡亂期間並屢立戰功，於民國42年除役。`
  },
  {
    name: "AT-11高級教練機",
    img: "/AT-11.jpg",
    content:
      "該機於民國39年加入本軍服役，供空軍官校28期至41期學生高級飛行訓練使用，至民國47年12月除役後，交由遠東航空公司改裝成運輸機使用。"
  },
  {
    name: "F-84雷霆式戰鬥機",
    img: "/6.jpg",
    content: `民國42年自美軍接收使用之噴射戰鬥機，使本軍進入噴射機時代，曾於臺海戰役中創造擊落多架米格機之紀錄，於民國53年除役。`
  },
  {
    name: "T-33A（射星式）噴射教練機",
    img: "/T-33A.jpg",
    content:
      "民國42年加入本軍服役，擔任部隊噴射機換裝任務，46年配賦官校高級飛行訓練用，為本軍使用時間極久，貢獻甚鉅之機種，至民國75年除役。"
  },
  {
    name: "RF-101A（巫毒式）偵察機",
    img: "/RF-101A.jpg",
    content:
      "我空軍於民國48年正式使用配有KA-1及KA-2照相機之RF-101A擔任空中偵察任務，為本軍首次使用超音速噴射戰術偵察機。我空軍第6大隊第4中隊最先使用此型機擔任對大陸偵察與偵照任務。其後移交第12中隊，繼續使用至民國57年除役。"
  },
  {
    name: "P-2V-7U海王星式電子偵察機",
    img: "/P-2V-7U.jpg",
    content:
      "民國46年我國開始由美國引進P-2V長程巡邏機，翌年，空軍第34中隊B-17機汰除，換裝P-2V機，執行大陸偵察任務，對於掌握中共軍事部署與動態，貢獻至大。民國50年至52年P-2V機執行深入大陸偵察任務被中共擊落多次，迄至民國53年美方再以P-2V-7U機提供我國，以加強防衛與偵測能力，機內配備響尾蛇飛彈4枚，並有偵測核子彈爆炸之空氣採樣器兩具。該機使用至民國56年除役。"
  },
  {
    name: "T-28A（啄木鳥式）教練機",
    img: "/T-28A.jpg",
    content:
      "民國49年加入本軍服役，供官校初級飛行訓練之用，59年部分系列機改裝為馬力較大，螺旋槳為三葉之T-28D，至民國67年除役。"
  },
  {
    name: "C-123（供給者式）運輸機",
    img: "/C-123.jpg",
    content:
      "民國52年3月，我空軍獲美國移交參加越戰C-123B型機五架，交由34中隊使用。民國53年6月8日，該機首次進入大陸，擔任對大陸空投任務。"
  },
  {
    name: "F-5自由鬥士式戰鬥機",
    img: "/7.jpg",
    content: `民國54年加入本軍服役，同年12月撥交部隊使用，執行臺海防衛任務，奠定本軍使用F-5系列戰機之基礎，於民國76年除役。`
  },
  {
    name: "F-86軍刀式戰鬥機",
    img: "/8.jpg",
    content: `民國43年獲得，民國47年臺海九二四溫州灣空戰，首創以響尾蛇飛彈擊落6架米格17機紀錄，另以機砲擊落6架敵機之戰機，於民國59年除役。`
  },
  {
    name: "F-100超級軍刀式戰鬥轟炸機",
    img: "/9.jpg",
    content: `民國49年加入本軍服役，執行防空、偵巡、掩護、出擊等任務，為世界上第一種平飛時能以超音速飛行之噴射機，於民國73年除役。`
  },
  {
    name: "RF-101A偵察機",
    img: "/10.jpg",
    content: `全世界首架具有水平飛行時速超過1600公里的戰鬥機，因使用需求而改為單座偵察機，將雷達與武器改裝成照相器材。因本身無裝配武器，遭受攻擊時僅能仰賴飛行員精湛飛行技術及飛機1.85馬赫的速度閃躲。`
  },
  {
    name: "F-104星式戰鬥機",
    img: "/11.jpg",
    content: `自民國49年以來防衛臺海領空安全達38年，主要擔任高空攔截任務，並曾於臺灣海峽空戰獲得擊落中共米格19機2架之戰績，為空軍進入倍音速時代之始，於民國87年除役。`
  },
  {
    name: "C-119（空中車廂式）運輸機",
    img: "/C-119.jpg",
    content:
      "我空軍於民國47年10月28日由美國撥借16架，48年7月1日正式接收，此機取代C-47除役後之運輸任務。自加入本軍服役後，曾先後擔任運輸、空投、空降、搜救及人造雨等任務，為我空軍運兵部隊主要機種，任重道遠，貢獻至偉，至民國86年除役。"
  },
  {
    name: "B-720（中美號） 空運機",
    img: "/B-720.jpg",
    content:
      "民國60年12月5日，我空軍向美國西北航空公司購得，並自61年起服役，擔任總統座機定名為「中美號」，並以零缺點的光榮紀錄，圓滿完成專送總統、政府高級首長及世界各國元首任務共306次，其中有7次國外專送任務，至民國80年9 月27日零時正式除役。"
  },
  {
    name: "C-118（舉重霸王式）運輸機",
    img: "/C-118.jpg",
    content:
      "我空軍於民國55年撥交專機部隊使用，以執行專機與行政運輸等任務為主，至民國78年12月除役。"
  },
  {
    name: "S-2A（追蹤者）反潛機",
    img: "/S-2A.jpg",
    content:
      "民國56年我空軍向美國購入此S-2A型機10架，並成立反潛機中隊，負責台海之偵潛及攻潛任務，至民國83年除役。"
  },
  {
    name: "HU-16（信天翁式）水陸兩用機",
    img: "/HU-16.jpg",
    content:
      "我空軍自民國57年使用該機擔任近海巡邏、救援等任務。性能優異，可連續實施12小時低空搜索飛行，對海面救難工作幫助極大，並可擔任反潛偵察或海面巡邏等任務。至民國76年除役。"
  },
  {
    name: "T-38（利爪式）教練機",
    img: "/T-38.jpg",
    content:
      "民國61年越戰期間，我國空軍應美國要求移交50架F-5A/B給南越空軍，而美國則移交21架T-38A教練機給我空軍作為過渡訓練機種，以彌補戰機數量不足，這批T-38A一直使用到74年才還給美國。民國84年我國空軍為了解決飛行員飛行訓練時數不足的問題，又再度向美軍租借40架T-38A（租期3年）部署於東指部花蓮空軍基地的第8戰鬥機大隊，作為戰機換裝、空地戰術專精及儀訓班資淺領隊前置訓練用，使得原本擔任這項訓練任務的F-5E/F能調往第一線擔任戰備，彌補新一代戰機換裝前的戰力空隙，至87年還給美國。"
  },
  {
    name: "T-CH-1中興號攻擊教練機 ",
    img: "/T-CH-1.jpg",
    content:
      "民國65年即加入本軍服役，擔任官校59至66期學生初級飛行訓練（型號T-CH-1），民國74年5月起改裝為攻擊機，撥交443聯隊30架，擔任海峽偵巡任務至民國87年除役。"
  },
  {
    name: "PL-1（介壽號）教練機",
    img: "/PL-1.jpg",
    content:
      "民國58年3月1日月起交官校做為學生初級班感覺及鑑定飛行教練機，由官校51-63期使用，至民國71年除役。"
  },
  {
    name: "AT-3（自強號）教練機",
    img: "/AT-3.jpg",
    content:
      "AT-3型機於69年7月17日第一架出廠，命名為「自強號」，9月16日試飛成功。民國74年5月17日撥交空軍軍官學校換裝，民國76年正式由空軍官校67期乙班飛行學生作高級飛行訓練使用，以取代T-33型噴射教練機迄今。"
  },
  {
    name: "C－130H力士型機",
    img: "/C-130H.jpg",
    content:
      "該機於民國75年陸續加入本軍服役，擔任台海偵巡、本外島空投、空運、國內外專機均有卓越績效，近年更因地球暖化造成氣候遽變，各種複合式天災頻傳，災區所需物資之運送均由此型機完成任務。"
  },
  {
    name: "S-2E反潛機",
    img: "/S-2E.jpg",
    content:
      "民國65年即加入本軍服役，主要負責台海之偵側、識別、追蹤及摧毀敵人艦潛任務為主，由反潛中隊使用至民國82年5月3日全數交廠改裝為S-2T（S-2T機88.07.01日移交海軍）。"
  },
  {
    name: "S-2T反潛機",
    img: "/S-2T.jpg",
    content:
      "本機於民國74年接收服役至民國104年4月27日除役，捍衛臺海達30年，服役期間多次執行漢光、華統及僑泰演習及歷次海鯊、海獵、聯興等操演任務，其中於民國83年5月25日的漢光10號演習期間，在距離臺南安平外海12.5浬處偵測到中共「R級」潛艦，隨即與我海軍艦隊執行聯合反潛獵殺任務。"
  },
  {
    name: "O-1G（獵鳥犬式）空中管制機",
    img: "/O-1G.jpg",
    content: `我國為加強空中管制能力，於民國60年使用該型機，成立空中管制機中隊，擔任前進空中管制及近海巡邏等任務。O-1G除能近距離偵巡管制外，亦具有攻擊性能為一靈巧而有攻擊力之小型空中管制機，適合前進基地短場起降需要，越戰期間，於密接支援作戰中，表現卓越。\n民國71年台南基地仍有17架，擔任空中管制及沿海巡邏等任務，至民國74年正式除役。`
  },
  {
    name: "E-2K鷹眼2000型預警機",
    img: "/E-2K.jpg",
    content: `本機種主要任務為執行全天候空中早期預警任務，強化防空作戰低空偵測效能，另可執行戰場管理、空中救災指揮管制、通信中繼與海空聯合截擊等任務。戰時陸基雷達陣地遭破壞時，可立即彌補雷達偵測涵蓋，接替攔截管制任務，確保防空作戰任務遂行。\nE-2型機配備被動偵測系統（ＰＤＳ），可偵測敵電子參數，並判斷目標物，有利作戰任務之遂行。具備空對地與空對空數據資料鏈路系統，為國軍空中作戰指揮重要平台，可增進國軍聯合作戰能力。為一可全天候執行戰備任務之預警機。
    `
  },
  {
    name: "S-70C-1A救護直升機",
    img: "/S-70C-1A.jpg",
    content:
      "平日擔負搜救、傷患後送及運補任務。執行88年921震災、90年7月30日桃芝颱風救災、91年7月9日元勝二號大陸漁工船失火搜救，98年8月8日八八水災撤離災民、運補，99年凡那比、梅姬颱風緊急救援等重大任務，均圓滿完成。"
  },
  {
    name: "B-737-800總統及外國元首座機",
    img: "/B-737-800.jpg",
    content: `於民國89年2月8日接收開始服役，擔任總統及外國元首座機，迄至現今。\n90年至93年執行敦邦外交任務、95年9月份執行群峰專案任務、95年11月份執行APEC會議(越南)任務、96年9月份執行APEC會議(澳洲)任務、99年6月份執行展鵬專案任務，各項座機任務順遂圓滿。`
  },
  {
    name: "T-34C型教練機",
    img: "/T-34C.jpg",
    content: `該機為三螺旋槳、低翼教練機，配有前後縱排雙座艙雙操縱系統，俾供飛行訓練之用；落單飛時只能在前座艙駕駛，該機又名良師型教練機。\n我國空軍民國74年5月由美採購44架作為空軍官校基本組學生初級飛行訓練之用。`
  },
  {
    name: "UH-1H直昇機",
    img: "/UH-1H.jpg",
    content:
      "民國59年12月14日，第一架合作生產的直升機在台中航空工業發展中心製成出廠。是日，舉行移交典禮，由空軍總司令陳衣凡上將移交陸軍總司令于豪章上將。此後，我陸軍增加「空中奇兵」，其戰力大為提升。"
  },
  {
    name: "P-3C型反潛機",
    img: "/P-3C.jpg",
    content:
      "具備全天候反潛作戰能力，作戰半徑達500浬，留空時間 12小時以上，可遂行大區域、長時間之反潛巡邏、監控與制壓；並具自衛式電子戰、攜帶反潛魚雷、攻船飛彈、水雷、大量聲標佈放、紅外線偵測系統及空用照像裝備等能力。"
  },
  {
    name: "UH-60M黑鷹直升機",
    img: "/UH-60M.jpg",
    content: `UH-60M「黑鷹」直升機是61年按照美國陸軍的要求進行設計，由美國西科斯基飛機公司生產的雙渦輪軸發動機、單旋翼直升機，是從西科斯基S-70直升機發展而來的一種中型通用及攻擊直升機。UH-60M是後續「升級版」機種，改良為寬旋翼系統，配備更強大的T700-GE-701D型引擎和最先進的電子設備、飛控系統和導航系統。\n在98年發生莫拉克風災，政府決定將當時尚未交機的黑鷹直升機先撥15架給內政部空中勤務總隊，強化該隊救災能量，之後，又因空軍救護隊所使用的S-70C海鷗直升機服役多年，有汰除必要，於是再撥15架給空軍救護隊使用。`
  },
  {
    name: "F-16戰隼式戰鬥機",
    img: "/12.jpg",
    content: `民國81年我空軍向美購得，於民國86年4月起陸續飛返國內，並於民國87年3月起成軍服役，具有全天候對空、對地作戰能力。`
  },
  {
    name: "幻象2000戰鬥機",
    img: "/13.jpg",
    content: `由法國達梭公司完成發展的幻象 2000-5 戰鬥機，其性能極為優越、配備精良。民國81年底，我國即向達梭公司購買60架幻象 2000-5 戰機，成為全世界第一個使用此型戰鬥機的國家，法國及其他國家並於後相繼購買。`
  },
  {
    name: "F-CK-1經國號戰鬥機",
    img: "/14.jpg",
    content: `我國首架自行設計製造之高性能戰鬥機。民國77年12月第一架原型機出廠，次年5月首次試飛成功，並於83年起陸續撥交空軍服役。連同原型機，共計生產137架。`
  },
  {
    name: "勇鷹號教練機",
    img: "/15.jpg",
    content: `空軍、中科院及漢翔三方合作，以IDF經國號戰機為基礎研發的高級教練機。勇鷹號從整機外型設計分析、風洞吹試、藍圖繪製、結構測試、地面整合測試、飛試驗證、部分系統件開發、生產製造組裝、地面輔助訓練系統到整體後勤支援系統，皆由國人自主完成，重新厚植臺灣航太工業人才鏈。`
  }
];

export { planeData };
