import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { withPage } from "../PageContainer";
import * as Widget from "../Components/Widget";
import { planeData } from "../Utils/PlaneData";

class PlanesPage extends React.Component {
  render() {
    return (
      <Wrapper>
        <img className="banner" src={"./images/004-3.png"} width="100%" />
        <Container>
          <Widget.Padding />
          <Widget.Row css={{ flex: 2.5, maxHeight: "70%" }}>
            <Widget.Col css={{ position: "relative", flex: 1 }}>
              <img
                style={{ marginLeft: 58 }}
                src={"./images/011.png"}
                width="50px"
                height="50px"
              />
              <Widget.BtnsWrapper>
                <Widget.HomeBtn to="/">
                  <img
                    className="back-btn"
                    src={"./images/008.png"}
                    width="115px"
                  />
                </Widget.HomeBtn>
              </Widget.BtnsWrapper>
            </Widget.Col>

            <Widget.Col
              css={{
                flex: 6,
                paddingRight: 40,
                overflow: "scroll"
              }}
            >
              {planeData.map(plane => (
                <Widget.Row css={{ marginTop: 30 }}>
                  <Widget.Col css={{ marginRight: 30 }}>
                    <img
                      style={{ marginLeft: 58 }}
                      src={`./images/planes${plane.img}`}
                      width="320px"
                      height="220px"
                    />
                  </Widget.Col>
                  <Widget.Col>
                    <Widget.Subtitle
                      css={{ fontSize: 30, lineHeight: "inherit" }}
                    >
                      {plane.name}
                    </Widget.Subtitle>
                    <Widget.Content
                      css={{ marginTop: 15, fontSize: 28, letterSpacing: 1 }}
                    >
                      {plane.content}
                    </Widget.Content>
                  </Widget.Col>
                </Widget.Row>
              ))}
            </Widget.Col>
          </Widget.Row>
          <Widget.BottomDeco />
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: white;
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;

  & > .banner {
    background-color: lightgray;
    border-radius: 30px 30px 0px 0px;
  }

  & .btns-wrapper {
    flex-grow: 1;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    & > .row {
      display: flex;
      flex-direction: row;
    }
  }
`;

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 84%
  );
`;

const Padding = styled.div`
  flex: 1;
  /* align-self: stretch; */
`;

const BackBtn = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 10px;

  & > p {
    color: #3e3a39;
    text-align: center;
    font-size: 30px;
    letter-spacing: 4pt;
    margin-left: 16px;
  }
`;

const Btn = styled(Link)`
  text-decoration: none;
  flex: 1;
  padding: 20px 0px;
  margin: 10px;
  border: 4px solid #3e3a39;
  border-radius: 20px;

  & > p {
    color: #3e3a39;
    text-align: center;
    font-size: 45px;
    letter-spacing: 6pt;
  }
  :active {
    background-color: #06347a;
    & > p {
      color: #fff;
    }
  }
`;

const BottomDeco = styled.div`
  margin-top: 60px;
  height: 50px;
  border-radius: 0px 0px 30px 30px;
  background-color: #f0f0f0;
`;

export default withPage(PlanesPage);
